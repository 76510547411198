import React from "react"
import { shape, string, array } from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContentBlocks from "../components/content-blocks"
import SEO from "../components/seo"

const propTypes = {
  data: shape({
    contentfulPage: shape({
      title: string,
      colourTheme: string,
      contentBlocks: array,
    }).isRequired,
  }).isRequired,
  pageContext: shape({
    languages: shape({
      en: string,
      fi: string,
    }).isRequired,
  }).isRequired,
  path: string.isRequired,
  location: shape().isRequired,
}

const Page = ({ data, pageContext, path, location }) => {
  let hasProductsHeader = false
  if (path.includes("/catalogue") || path.includes("/tuoteluettelo")) {
    hasProductsHeader = true
  }
  const { languages: slugs } = pageContext
  const { title, colourTheme, contentBlocks } = data.contentfulPage
  return (
    <Layout
      hasProductsHeader={hasProductsHeader}
      slugs={slugs}
      colourTheme={colourTheme}
      currentPath={path}
    >
      <SEO title={title} location={location.pathname} />
      <ContentBlocks blocks={contentBlocks} />
    </Layout>
  )
}

export default Page

Page.propTypes = propTypes

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      colourTheme
      contentBlocks {
        __typename
        ... on ContentfulHeroImage {
          id
          image {
            contentful_id
            title
          }
        }
        ... on ContentfulPageHeader {
          title
          id
          childContentfulPageHeaderColumn2RichTextNode {
            json
          }
          childContentfulPageHeaderColumn3RichTextNode {
            json
          }
        }
        ... on ContentfulThreeColumnBlock {
          title
          id
          columns {
            id
            title
            image {
              contentful_id
              title
            }
            body {
              json
            }
            button {
              title
              slug
            }
          }
        }
        ... on ContentfulContactList {
          title
          id
          columns {
            id
            title
            image {
              contentful_id
              title
            }
            body {
              json
            }
          }
        }
      }
    }
  }
`
